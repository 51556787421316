import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { base, db } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import RichTextEditor from 'html-verify-react-rte';
import {PhotoshopPicker} from "react-color";
import {Modal} from "reactstrap";
import DatePicker from "react-datepicker";

class SetUpFollowUpEmail extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            emailVariables: '',
            loading:true,
            showTabOneemailComponents: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        this.emailVariablesRef = base.listenToDoc(`emailVariables/default`, {
            context: this,
            state: 'emailVariables',
            then(data){
                const emailVariables = data.emailVariables || {};
                let advanced = false;
                if(emailVariables && emailVariables.sender){
                    advanced = true;
                }
                this.setState({
                    loading: false,
                    advanced: advanced,
                    emailVariables: emailVariables
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.emailVariablesRef);
    }

    openColorPicker(colorToChange){
        this.setState({
            colorPickerShow: true,
            colorToShow: this.state[colorToChange],
            colorToUpdate: colorToChange
        })
    }

    handleChange(event){
        let target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if(target.type === 'checkbox' && target.name === "advanced"){
            this.setState({[target.name]: value})
        } else {
            let emailVariablesCopy = this.state.emailVariables;
            emailVariablesCopy[target.name] = value
            this.setState({ emailVariables:  emailVariablesCopy});
        }
    }

    handleImageChange(event){
      const name_of_file = event.target.name;
      const target = this[name_of_file];
      const file_to_update =target.files[0];
      this.setState({loading:true})
      const vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              let emailVariablesCopy = vm.state.emailVariables;
              emailVariablesCopy[name_of_file] = res.imageUrl
              vm.setState({
                  emailVariables: emailVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        const emailVariablesCopy = this.state.emailVariables;
        emailVariablesCopy[this.state.colorToUpdate] = this.state.colorToShow;
        this.setState({
            emailVariables: emailVariablesCopy,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    handleRichTextChange = (name, text) => {
        let copy = this.state.emailVariables || {};
        copy[name] = text;
        this.setState({ emailVariables:  copy});
    }

    async handleSubmit(event) {
      event.preventDefault();
      const emailVariables = this.state.emailVariables;
      let sender = emailVariables.followUpSender || "";
      let subjectLine = emailVariables.followUpSubjectLine || "";
      let preview = emailVariables.followUpPreviewText || "";
      let imageLink = emailVariables.followUpImageLink || "";
      let image = emailVariables.followUpImage || "";
      let followUpShowButton = emailVariables.followUpShowButton || "";
      let numberOfHoursBeforeFollowUp = emailVariables.numberOfHoursBeforeFollowUp || 0;
      let descriptionText = emailVariables.followUpDescriptionText || "";
      let followUpShowButtonText = emailVariables.followUpShowButtonText || "";
      let followUpShowButtonColor = emailVariables.followUpShowButtonColor || "";
      let followUpShowButtonTextColor = emailVariables.followUpShowButtonTextColor || "";
      let expiresAtDateTime = emailVariables.expiresAtDateTime || "";
      if(expiresAtDateTime && typeof expiresAtDateTime === 'object'){
        expiresAtDateTime = expiresAtDateTime.getTime();
      }
      if(descriptionText === "<p><br></p>"){
          descriptionText = "";
      } else {
            descriptionText = descriptionText.toString('html')
      }
      this.setState({loading:true});
      const updateRulesObject = {};
      updateRulesObject['followUpDescriptionText'] = descriptionText;
      updateRulesObject['followUpSubjectLine'] = subjectLine;
      updateRulesObject['followUpSender'] = sender;
      updateRulesObject['followUpImageLink'] = imageLink;
      updateRulesObject['followUpImage'] = image;
      updateRulesObject['followUpPreviewText'] = preview;
      updateRulesObject['followUpShowButton'] = followUpShowButton;
      updateRulesObject['followUpShowButtonText'] = followUpShowButtonText;
      updateRulesObject['followUpShowButtonTextColor'] = followUpShowButtonTextColor;
      updateRulesObject['followUpShowButtonColor'] = followUpShowButtonColor;
      updateRulesObject['numberOfHoursBeforeFollowUp'] = numberOfHoursBeforeFollowUp;
      updateRulesObject['expiresAtDateTime'] = expiresAtDateTime;
      try {
          await db.collection('emailVariables').doc("default").set(updateRulesObject, {merge: true})
      } catch (e) {
          this.setState({loading:false})
          await swal({
              title: 'There was some error!',
              text: 'Try again and if the problem persists try logging out and logging back in',
              type: 'error',
              confirmButtonText: 'Ok'
          })
          return
      }
      this.setState({loading:false})
      await swal({
            title: 'Variables Updated!',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    render() {
        let sender = this.state.emailVariables.followUpSender || "";
        let subjectLine = this.state.emailVariables.followUpSubjectLine || "";
        let preview = this.state.emailVariables.followUpPreviewText || "";
        let followUpDescriptionText = this.state.emailVariables.followUpDescriptionText || "";
        let numberOfHoursBeforeFollowUp = this.state.emailVariables.numberOfHoursBeforeFollowUp || 0;
        let followUpShowButton = this.state.emailVariables.followUpShowButton || false;
        let followUpShowButtonText = this.state.emailVariables.followUpShowButtonText || "";
        let followUpShowButtonTextColor = this.state.emailVariables.followUpShowButtonTextColor;
        let followUpShowButtonColor = this.state.emailVariables.followUpShowButtonColor;
        if(typeof followUpDescriptionText === 'string'){
            followUpDescriptionText = RichTextEditor.createValueFromString(followUpDescriptionText, 'html');
        } else if(!followUpDescriptionText) {
            followUpDescriptionText = RichTextEditor.createEmptyValue()
        }
        let followUpImageLink = this.state.emailVariables.followUpImageLink || "";
        let followUpImage = this.state.emailVariables.followUpImage || "";
        let expiresAtDateTime = this.state.emailVariables.expiresAtDateTime;
        if(!expiresAtDateTime){
            expiresAtDateTime = null;
        }
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <p className="admin-header-text" style={{marginBottom:0}}>Follow Up Email Branding</p>
              <p className="admin-subheader-text">This is where you can edit your follow up communication sent to fans</p>
              <div className="container">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-email-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:10}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <div className="mb-3">
                          <label htmlFor="numberOfHoursBeforeFollowUp" className="form-label">Number of hours to send AFTER first email sent</label>
                          <div className="form-text">This is the subject line that your fans will see when they receive their messages (If you have both a number of hours and a datetime we will always send whichever is comes up first)</div>
                          <input type="number" id="numberOfHoursBeforeFollowUp" name="numberOfHoursBeforeFollowUp" className="form-control" value={numberOfHoursBeforeFollowUp} onChange={this.handleChange} />
                          OR/AND
                          <div className="col-md-3">
                              <DatePicker isClearable showTimeInput dateFormat="Pp" selected={expiresAtDateTime} onChange={(date) => this.setState((prevState) => ({emailVariables: {...prevState, "expiresAtDateTime": date}}))}/>
                          </div>
                      </div>
                      <div className="mb-3">
                          <label htmlFor="followUpSubjectLine" className="form-label">Subject Line</label>
                          <div className="form-text">This is the subject line that your fans will see when they receive their messages</div>
                          <input id="followUpSubjectLine" name="followUpSubjectLine" className="form-control" value={subjectLine} onChange={this.handleChange} />
                      </div>
                      {!isMlbApp &&
                          <>
                              <div className="mb-3">
                                  <label htmlFor="followUpPreviewText" className="form-label">Preview Text</label>
                                  <div className="form-text">This is the preview text for the email, if left blank it will be replaced by the subject line. For mobile optimization the length should be between 35-50 characters but for desktop the preview can be up to 140 characters and beyond</div>
                                  <input id="followUpPreviewText" name="followUpPreviewText" className="form-control" value={preview} onChange={this.handleChange}/>
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="followUpDescriptionText" className="form-label">Description Text</label>
                                  <div className="form-text">This is where you can tell fans where to contact you with issues. (Example: Having issues? Email info@help.com for help.)</div>
                                  <RichTextEditor toolbarConfig={toolbarConfig} style={{minHeight: '100px'}} id="followUpDescriptionText" name="followUpDescriptionText" value={followUpDescriptionText} onChange={(text) => this.handleRichTextChange("followUpDescriptionText", text)}/>
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="followUpImageLink" className="form-label">Follow Up Image Link</label>
                                  <div className="form-text">This link, if filled in, will be accessible by clicking the Follow Up Image</div>
                                  <input id="followUpImageLink" name="followUpImageLink" className="form-control" value={followUpImageLink} onChange={this.handleChange}/>
                              </div>
                              <div className="mb-3" align="center">
                                  <img src={followUpImage} width="auto" height="100px" alt=""/>
                                  <br/>
                                  <label htmlFor="followUpImage" className="form-label">Follow Up Image<br/>(700px X 400px)</label>
                                  <div className="mb-3">
                                      <input style={{display:'none'}}  id="followUpImage" name="followUpImage" type="file" ref={input => {this.followUpImage = input; }} onChange={this.handleImageChange}/>
                                      <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('followUpImage').click()} />
                                  </div>
                              </div>
                              <div className="mb-3 form-check">
                                  <input className="form-check-input" type="checkbox" checked={followUpShowButton} id="followUpShowButton" name="followUpShowButton" onChange={this.handleChange}/>
                                  <label htmlFor="followUpShowButton" className="form-check-label">Show Button</label>
                              </div>
                              {followUpShowButton &&
                                  <>
                                      <div className="form-group">
                                          <label htmlFor="followUpShowButtonText">Button Text</label>
                                          <input id="followUpShowButtonText" name="followUpShowButtonText" type="text" className="form-control" value={followUpShowButtonText} onChange={this.handleChange} placeholder={"See Prize!"}/>
                                      </div>
                                      <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-inline">
                                              <span style={{marginRight: 10}}
                                                    className="fa fa-eyedropper mobile-hide"
                                                    onClick={() => this.openColorPicker("followUpShowButtonTextColor")}/>
                                                  <div style={{
                                                      backgroundColor: followUpShowButtonTextColor,
                                                      marginRight: 10,
                                                      border: "solid 1px",
                                                      minHeight: 20,
                                                      width: 20,
                                                      display: "inline-block"
                                                  }}/>
                                                  <label htmlFor="followUpShowButtonTextColor" style={{marginRight: 10}}>Button Text Color</label>
                                                  <input id="followUpShowButtonTextColor" name="followUpShowButtonTextColor"
                                                         type="text" className="form-control"
                                                         value={followUpShowButtonTextColor}
                                                         onChange={this.handleChange} placeholder="#000"/>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                              <div className="form-inline">
                                              <span style={{marginRight: 10}}
                                                    className="fa fa-eyedropper mobile-hide"
                                                    onClick={() => this.openColorPicker("followUpShowButtonColor")}/>
                                                  <div style={{
                                                      backgroundColor: followUpShowButtonColor,
                                                      marginRight: 10,
                                                      border: "solid 1px",
                                                      minHeight: 20,
                                                      width: 20,
                                                      display: "inline-block"
                                                  }}/>
                                                  <label htmlFor="followUpShowButtonColor" style={{marginRight: 10}}>Button Color</label>
                                                  <input id="followUpShowButtonColor" name="followUpShowButtonColor"
                                                         type="text" className="form-control"
                                                         value={followUpShowButtonColor}
                                                         onChange={this.handleChange} placeholder="#000"/>
                                              </div>
                                          </div>
                                      </div>
                                  </>
                              }
                              <div className="mb-3 form-check">
                                  <input className="form-check-input" type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                                  <label htmlFor="showAdvancedSettings" className="form-check-label">Advanced</label>
                              </div>
                              {this.state.advanced &&
                                  <div className="mb-3">
                                      <label htmlFor="followUpSender" className="form-label">Email Sender</label>
                                      <div className="form-text">This is the sender your fans will see when they receive their winning emails. BE AWARE: changing the sender could adversely impact delivery rates</div>
                                      <input id="followUpSender" name="followUpSender" type="email" className="form-control" value={sender} onChange={this.handleChange} />
                                  </div>
                              }
                          </>
                      }
                  </form>
                </div>
              </div>
            </div>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpFollowUpEmail
